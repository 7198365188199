.custom-new-search-input .ant-input-affix-wrapper{
    background-color: #F4F4F4 !important;
    border-radius:8px !important;
    height: 48px !important;
    padding: 15px 16px 15px 16px !important;
    box-shadow: 0px -1px 0px 0px #E0E0E0 inset !important;
}

.custom-new-search-input .ant-input{
    background-color: #F4F4F4 !important;
    font-size: 14px !important;
}

.custom-new-search-input .ant-input::placeholder{
    color: #A8A8A8 !important;
}