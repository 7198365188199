@media only screen and (max-width: 570px) {
    .header-login-welcome{
        font-size: 10px !important;
    }
    .header-login-link{
        font-size: 12px !important;
    }
    .header-login-icon{
        font-size: 35px !important;
    }
}