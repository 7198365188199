@media only screen and (max-width: 885px) {
    .mobile-header-wrapper{
        display: block;
    }
    .header-wrapper{
        display: none;
    }
}

@media only screen and (min-width: 885px) {
    .mobile-header-wrapper{
        display: none;
    }
    .header-wrapper{
        display: block;
    }
}