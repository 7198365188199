.course-table .ant-table-row:hover{
    cursor: pointer;
}

.course-content-list a:hover{
    color:#00a59f !important;
}

.login-or-by-plan-modal .ant-modal-content{
    padding: 0px !important;
}