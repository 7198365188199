@media only screen and (max-width: 885px) {
    .mobile-dashboard-side-menu{
        display: block;
    }
    .dashboard-side-menu{
        display: none;
    }
}

@media only screen and (min-width: 885px) {
    .mobile-dashboard-side-menu{
        display: none;
    }
    .dashboard-side-menu{
        display: block;
    }
}