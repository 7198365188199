@media only screen and (max-width: 570px) {
    .contact-us-text{
        font-size: 10px !important;
    }
    .contact-us-mobile{
        font-size: 14px !important;
    }
    .contact-us-icon{
        font-size: 30px !important;
    }
}