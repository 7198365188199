.faq-search-input .ant-input-affix-wrapper{
    background-color: #f4f4f4 !important;
    border-start-start-radius: 10px !important;
    border-end-start-radius: 10px !important;
    height: 50px !important;
    padding: 15px !important;
}

.faq-search-input .ant-input{
    background-color: #f4f4f4 !important;
    font-size: 12px !important;
}

.faq-search-input .ant-input::placeholder{
    color: #5d5d5d !important;
}